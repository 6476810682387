<template>
	<div>
		<div>
			<b-row>
				<b-col cols="6">
					<b-row>
						<b-col>
							<base-input
								v-model="form.course_name"
								label="Name"
								:validator="$v.form.course_name"
								:messages="localMessages"
							></base-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								v-model="form.category"
								label="Category"
								:validator="$v.form.category"
								:messages="localMessages"
							></base-input>
						</b-col>
						<b-col>
							<div class="form-group">
								<label>Duration</label>
								<flat-pickr
									class="timePicker form-control"
									:class="[
										{
											'is-invalid':
												$v.form.duration.$error,
										},
									]"
									:placeholder="form.duration"
									v-model="form.duration"
									:config="datePickerConf"
								></flat-pickr>
								<div
									class="flatpickr-error error-message"
									v-if="
										$v.form.duration.$dirty &&
											$v.form.duration.$error
									"
								>
									<div v-if="!$v.form.duration.required">
										<i
											class="fa fa-exclamation-triangle"
										></i>
										Duration cannot be empty
									</div>
								</div>
							</div>
						</b-col>
						<b-col>
							<div class="form-group">
								<label>Price (MYR)</label>
								<vue-numeric
									:class="[
										{
											'is-invalid':
												$v.form.course_price.$error,
										},
									]"
									class="form-control"
									separator=","
									empty-value=""
									v-bind:precision="2"
									v-model="form.course_price"
								></vue-numeric>
								<div
									class="flatpickr-error error-message"
									v-if="
										$v.form.course_price.$dirty &&
											$v.form.course_price.$error
									"
								>
									<div v-if="!$v.form.course_price.required">
										<i
											class="fa fa-exclamation-triangle"
										></i>
										Price cannot be empty
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<div class="form-group">
								<label v-if="createMode"
									>Upload background image</label
								>
								<label v-if="!createMode"
									>Update background image</label
								>
								<b-form-file
									v-model="form.file_name"
									:class="{
										'is-invalid':
											$v.form.file_name.$invalid,
									}"
									ref="file-input"
									accept=".png, .jpg, .jpeg "
									placeholder="Choose a file or drop it here..."
									drop-placeholder="Drop file here..."
									class="bayo-form-file w-100"
								></b-form-file>
							</div>
						</b-col>
						<b-col class="mt-7" cols="3" v-if="form.file_name">
							<b-button
								@click="resetFile()"
								variant="danger"
								block
							>
								Reset
							</b-button>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								v-model="form.description"
								label="Description"
								:validator="$v.form.description"
								:messages="localMessages"
							></base-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								type="textarea"
								v-model="form.long_description"
								label="Detail Description"
								:validator="$v.form.long_description"
								:messages="localMessages"
							></base-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-row>
								<b-col>
									<label class="pt-3"
										>Selected Modules</label
									></b-col
								>
								<b-col cols="auto"
									><b-button
										variant="link"
										@click="onClearAllSelected"
										>Clear</b-button
									></b-col
								>
							</b-row>
							<div
								class="p-4 border-danger border text-danger"
								v-if="
									$v.form.trainee_modules_list.$dirty &&
										$v.form.trainee_modules_list.$invalid
								"
							>
								No module or quiz selected
							</div>
							<div
								class="p-4 bg-secondary"
								v-if="tempList.length === 0"
							>
								No module or quiz selected yet
							</div>
							<div>
								<draggable
									:list="tempList"
									class="list-group"
									ghost-class="ghost"
									:move="checkMove"
									@start="dragging = true"
									@end="dragging = false"
								>
									<div
										class="list-group-item"
										v-for="(item, index) in tempList"
										:key="`item-${index}`"
									>
										<template v-if="item.module_name">{{
											item.module_name
										}}</template>
									</div>
								</draggable>
							</div>
						</b-col>
					</b-row>
					<b-row class="mt-6">
						<b-col class="d-flex flex-row-reverse">
							<b-button
								@click="onSubmit"
								class="ml-3"
								variant="primary"
								size="lg"
							>
								<template v-if="createMode">Submit</template>
								<template v-if="!createMode">Update</template>
							</b-button>
							<b-button
								@click="onClearForm"
								class="ml-3"
								variant="secondary"
								size="lg"
							>
								Clear
							</b-button>
						</b-col>
					</b-row>
				</b-col>
				<b-col v-if="modalSize === 'xl'">
					<div>
						<h4>List of Modules</h4>
						<p class="mb-5">
							Select or unselect the module you wanted to include
							or exclude in this course
						</p>

						<b-row class="mb-3">
							<b-col
								><div>
									<b-button
										@click="selectAllModuleRows"
										class="mr-3"
										size="sm"
										>Select All</b-button
									>
									<b-button
										@click="clearSelectedModule"
										class="mr-3"
										size="sm"
										>Clear Selected</b-button
									>
								</div></b-col
							>
							<b-col>
								<template>
									<b-input-group size="sm">
										<b-form-input
											v-model="filterModule"
											type="search"
											placeholder="Type to Search"
										></b-form-input>

										<b-input-group-append>
											<b-button
												:disabled="!filterModule"
												@click="filterModule = ''"
												>Clear</b-button
											>
										</b-input-group-append>
									</b-input-group>
								</template>
							</b-col>
						</b-row>
						<b-table
							sticky-header="540px"
							ref="selectableModuleTable"
							:filter="filterModule"
							striped
							selectable
							bordered
							empty-text="No quiz created. Please create the quiz first"
							show-empty
							hover
							:items="ModuleCollectionList"
							:fields="moduleListFields"
							@row-selected="onModuleRowSelected"
						></b-table>
					</div>
				</b-col>
			</b-row>

			<b-row class="mt-6">
				<b-col class="d-flex flex-row-reverse">
					<b-button
						variant="secondary"
						size="lg"
						@click="closeModal('QuestionAnswerModal')"
						>Cancel
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import {
	CREATE_TRAINEE_COURSE,
	GET_ALL_TRAINEE_COURSE_LIST,
	GET_ALL_TRAINEE_MODULE_LIST,
	GET_TRAINEE_COURSE_LIST,
	UPDATE_TRAINEE_COURSE,
} from "@/core/services/store/modules/globaladmin/trainee.module";

import SystemHelper from "@/core/services/systemhelper.service";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import draggable from "vuedraggable";
import _ from "lodash";

export default {
	mixins: [validationMixin],
	components: { flatPickr, draggable },
	props: {
		createMode: { type: Boolean },
		selectedCourse: { type: Object },
		modalSize: { type: String, default: "" },
	},
	data() {
		return {
			filterModule: "",
			form: {
				course_name: "",
				course_price: "",
				category: "",
				duration: "",
				description: "",
				long_description: "",
				trainee_modules_list: null,
				file_name: null,
			},
			moduleListFields: [{ key: "description" }, { key: "category" }],
			datePickerConf: {
				enableTime: true,
				noCalendar: true,
				dateFormat: "H:i:S",
				maxDate: "today",
				time_24hr: true,
				minTime: "00:01:00N",
				enableSeconds: true,
				minuteIncrement: 1,
				secondIncrement: 1,
			},
			tempList: [],
			selectedModuleList: [],
		};
	},
	validations: {
		form: {
			file_name: { required },
			course_name: { required },
			course_price: { required },
			category: { required },
			description: { required },
			duration: { required },
			long_description: { required },
			trainee_modules_list: { required },
		},
	},
	computed: {
		ModuleCollectionList() {
			const list = this.$store.state.globaladmin_trainee
				.allTraineeModuleList;
			console.log(list);
			if (Array.isArray(list) && list.length) {
				return list;
			} else {
				return [];
			}
		},
		processedTempList() {
			let newArr = [];
			this.tempList.forEach((item, index) => {
				if (item.trainee_module_id) {
					newArr.push({
						trainee_module_id: item.trainee_module_id,
						order_no: (index + 1).toString(),
						percentage: (100 / this.tempList.length).toFixed(2),
					});
				} else {
					newArr = [];
				}
			});

			newArr = _.sortBy(newArr, ["order_no"], ["asc"]);

			return newArr;
		},
	},
	methods: {
		onClearAllSelected() {
			this.$refs.selectableModuleTable.clearSelected();
			this.tempList = [];
		},
		selectModuleRow(tableIndex) {
			// Rows are indexed from 0, so the third row is index 2
			this.$refs.selectableModuleTable.selectRow(tableIndex);
		},
		onModuleRowSelected(items) {
			// this.form.quiz_list = items;
			this.selectedModuleList = items;

			this.tempList = this.selectedModuleList;
		},
		selectAllModuleRows() {
			this.$refs.selectableModuleTable.selectAllRows();
		},
		clearSelectedModule() {
			this.$refs.selectableModuleTable.clearSelected();
		},
		checkMove: function(e) {
			window.console.log("Future index: " + e.draggedContext.futureIndex);
		},
		getFileType(file) {
			let ianaFileType = file.name;
			let x = ianaFileType.split(".").pop();
			let fileType;

			if (x === "mp4") {
				fileType = "vid";
			} else {
				fileType = x;
			}
			return fileType;
		},
		onSubmit() {
			this.form.trainee_modules_list = JSON.stringify(
				this.processedTempList
			);
			this.$v.form.$touch();

			if (this.$v.form.$anyError) {
				return;
			}
			if (this.processedTempList.length < 1) {
				SystemHelper.basicSwalHandler(
					"Validation Error",
					"You have to add at least one modules",
					"danger"
				);
				return;
			}

			let formData = new FormData();

			for (var key in this.form) {
				formData.append(key, this.form[key]);
			}
			formData.append("file_type", this.getFileType(this.form.file_name));

			if (!this.createMode) {
				SystemHelper.confirmationDialogHandler(
					{ title: "Confirmation", html: "Update Module?" },
					{ action: UPDATE_TRAINEE_COURSE, payload: formData },
					[
						{
							action: GET_ALL_TRAINEE_COURSE_LIST,
							payload: this.defaultPayload,
						},
					],
					this.$parent.modalId
				);
			} else {
				SystemHelper.confirmationDialogHandler(
					{ title: "Confirmation", html: "Create Module?" },
					{ action: CREATE_TRAINEE_COURSE, payload: formData },
					[
						{
							action: GET_ALL_TRAINEE_COURSE_LIST,
							payload: this.defaultPayload,
						},
					],
					this.$parent.modalId
				);
			}
		},
		onClearForm() {
			this.$v.form.$reset();
			this.form = {
				file_name: null,
				module_name: "",
				category: "",
				duration: "",
				course_price: "",
				description: "",
				long_description: "",
				trainee_modules_list: [],
			};
			this.selectedModuleList = [];
			this.selectedMaterialList = [];
			this.tempList = [];
		},
		resetFile() {
			this.$refs["file-input"].reset();
		},
	},
	mounted() {
		this.$store.dispatch(GET_ALL_TRAINEE_MODULE_LIST, this.defaultPayload);

		if (!this.createMode) {
			this.form = this.selectedCourse;
			console.log("Form: ", this.form);

			this.$store
				.dispatch(GET_TRAINEE_COURSE_LIST, {
					trainee_course_id: this.form.trainee_course_id,
				})
				.then((res) => {
					if (res.response_code === 2100) {
						let selectedModule = res.data.posts[0].List_of_Modules;
						console.log("before", selectedModule);

						selectedModule = _.orderBy(
							selectedModule,
							["order_no"],
							["desc"]
						);

						console.log("after", selectedModule);

						selectedModule.forEach((item) => {
							let index = this.ModuleCollectionList.findIndex(
								(x) =>
									x.trainee_module_id ===
									item.trainee_module_id
							);
							this.selectModuleRow(index);
							console.log("selected", index);
						});
					} else {
						SystemHelper.errorHandler(res, null, "error");
					}
				});
		}
	},
};
</script>
