<template>
	<b-modal
		id="CourseModal"
		:visible="modalVisibility(modalId)"
		:title="`${textCreateMode(createMode)} Course`"
		@show="onShow()"
		@hidden="resetModal()"
		@ok="onOk"
		hide-footer
		size="xl"
		ok-only
		no-close-on-backdrop
		ok-title="Done"
	>
		<course-form
			:create-mode="createMode"
			:selected-course="selectedCourse"
			:modal-size="modalSize"
		></course-form>
	</b-modal>
</template>

<script>
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
// import moment from "moment";

import CourseForm from "@/view/pages/globaladmin/course/forms/CourseForm.vue";

export default {
	components: { CourseForm },
	props: {
		createMode: { type: Boolean, default: false },
	},
	data() {
		return {
			selectedCourse: null,
			filterMaterial: "",
			modalSize: "xl",
			readOnly: true,
			modalId: "CourseModal",
			isBusy: true,
			selected: "",
			selectedMaterialList: [],
			materialListFields: [
				{ key: "description" },
				{ key: "answer_type" },
				{ key: "category" },
			],
		};
	},
	methods: {
		textCreateMode(mode) {
			if (mode) {
				return "Create";
			} else {
				return "Update";
			}
		},
		textApproveStatus(id) {
			let txt;
			switch (id) {
				case 1:
					txt = "Approved";
					break;
				case 2:
					txt = "Pending";
					break;
				case 3:
					txt = "Rejected";
					break;
			}
			return txt;
		},
		onShow() {
			this.selectedCourse = JSON.parse(
				JSON.stringify(this.$parent.selectedCourse)
			);
		},
		onOk() {
			this.$store.dispatch(SET_MODAL_STATE, null);
		},
		resetModal() {
			this.selectedCourse = null;
			this.$store.dispatch(SET_MODAL_STATE, null);
		},
	},
};
</script>
<style lang="scss">
.timePicker::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #000;
}
.timePicker:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #000;
	opacity: 1;
}
.timePicker::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #000;
	opacity: 1;
}
.timePicker:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #000;
}
</style>
