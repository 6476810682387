<template>
  <div>
    <KTCard title="Course List">
      <template v-slot:toolbar>
        <b-button @click="onOpenModal" variant="primary" size="sm"
          ><i class="fa fa-plus"></i> Create New Course</b-button
        >
      </template>

      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          sticky-header="500px"
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          bordered
          show-empty
          hover
          :items="moduleItemList"
          :fields="fields"
        >
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                @click="updateCourse(row.item)"
                variant="primary"
                size="sm"
              >
                Details
              </b-button>
              <b-button
                @click="onRemove(row.item.trainee_course_id)"
                variant="danger"
                size="sm"
              >
                Delete
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>

    <course-modal :create-mode="createMode"></course-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

import { validationMixin } from "vuelidate";

import SystemHelper from "@/core/services/systemhelper.service";
import {
  DELETE_TRAINEE_COURSE,
  GET_ALL_TRAINEE_COURSE_LIST,
} from "@/core/services/store/modules/globaladmin/trainee.module";
import CourseModal from "@/view/pages/globaladmin/course/modals/CourseModal";

export default {
  mixins: [validationMixin],
  components: { CourseModal },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Global Admin" },
      { title: "Course" },
    ]);

    this.$store.dispatch(GET_ALL_TRAINEE_COURSE_LIST, this.defaultPayload);
  },
  computed: {
    tableSettings() {
      const list = this.$store.state.globaladmin_trainee.allTraineeCourseList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
        pagination: true,
      };
    },
    moduleItemList() {
      const list = this.$store.state.globaladmin_trainee.allTraineeCourseList;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      createMode: false,
      modalId: "CourseModal",
      selectedTrainee: null,
      filter: "",
      currentPage: 1,
      fields: [
        {
          key: "trainee_course_id",
          label: "Course ID",
          sortable: true,
        },
        {
          key: "course_name",
          label: "Course Name",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: false,
          class: "text-right",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-right",
        },
      ],
    };
  },

  methods: {
    onOpenModal() {
      this.createMode = true;
      this.openModal("CourseModal");
    },
    updateCourse(course) {
      this.createMode = false;
      // assign selected trainee
      this.selectedCourse = course;

      // open permission modal
      this.openModal("CourseModal");
    },
    onRemove(courseId) {
      alert(courseId);
      let payload = {
        trainee_course_id: courseId,
      };

      // open permission modal
      SystemHelper.confirmationDialogHandler(
        { title: "Confirmation", html: "Delete Course?" },
        { action: DELETE_TRAINEE_COURSE, payload: payload },
        [{ action: GET_ALL_TRAINEE_COURSE_LIST, payload: this.defaultPayload }],
        null
      );
    },
  },
};
</script>
